import Vue from 'vue'
import Router from "vue-router";
import App from './App.vue'
import {fas} from "@fortawesome/free-solid-svg-icons";
import {fab} from "@fortawesome/free-brands-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome';
import {BootstrapVue} from 'bootstrap-vue';
import VueGtag from 'vue-gtag';

// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/styles/main.css';

import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'

import * as Sentry from "@sentry/vue";
// import {Integrations} from "@sentry/tracing";
import 'toastr/build/toastr.min.css';

import routes from "@/router";

Vue.use(Router);

const router = new Router({
    routes
});

function title(str) {
    return `du-lua.dev - ${str}`;
}

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = title(to.meta.title) || title('Lua tools for Dual Universe');
    });
});

Vue.config.productionTip = false

library.add(fas, fab);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

Vue.component('v-select', vSelect)

Vue.use(BootstrapVue);

Vue.use(VueGtag, {
    config: {
        id: "G-P78DG9WYQQ",
        params: {
            send_page_view: true
        }
    }
});

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        Vue,
        dsn: "https://af438b0cb522467b954bb66edacb9590@o298989.ingest.sentry.io/6173869",
        // integrations: [
        //     new Integrations.BrowserTracing({
        //         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        //         tracingOrigins: ["localhost", "my-site-url.com", /^\//],
        //     }),
        // ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
