<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1>Utilities</h1>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <ColorPickerConverter />
      </div>
    </div>
  </div>
</template>

<script>
import ColorPickerConverter from "@/components/utilities/ColorPickerConverter";
export default {
  name: "UtilitiesComponent",
  components: {ColorPickerConverter}
}
</script>

<style scoped>

</style>
