<template>
  <div class="container-fluid mt-3">
    <div class="container">
      <div class="row">
        <div class="col">
          <label class="fw-bold text-white" for="searchable">Search item name</label>
          <div class="input-group">
            <input id="searchable" v-model="searchText" class="form-control" placeholder="Search Item Name" type="text">
            <div class="input-group-append">
              <div class="dropdown">
                <button class="btn btn-success dropdown-toggle" @click="dropDownToggle = !dropDownToggle">
                  <font-awesome-icon icon="download"/>
                  Download
                </button>
                <ul :class="dropDownToggle ? 'dropdown-menu show' : 'dropdown-menu'">
                  <li><a class="dropdown-item" @click="download('lua')">Lua Table</a></li>
                  <li><a class="dropdown-item" @click="download('json')">JSON</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          &nbsp;
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <button v-for="(c,i) in columns" :key="'btn_col_' + i"
                  :class="c ? 'btn btn-success btn-sm me-1 mb-1' : 'btn btn-info btn-sm me-1 mb-1'"
                  @click="columns[i] = !columns[i]">
            <font-awesome-icon v-show="!c" icon="square"/>
            <font-awesome-icon v-show="c" icon="check"/>
            {{ i }}
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <table :class="rowColorsOnTier ? 'table table-sm table-hover' : 'table table-sm table-hover table-striped'" style="margin-bottom: 75px;">
          <thead class="text-white">
          <d-u-item-list-pagination></d-u-item-list-pagination>
          <tr>
            <th v-if="columns.id">id</th>
            <th v-if="columns.displayNameWithSize">displayNameWithSize</th>
            <th v-if="columns.tier">tier</th>
            <th v-if="columns.size">size</th>
            <th v-if="columns.unitMass">unitMass</th>
            <th v-if="columns.unitVolume">unitVolume</th>
            <th v-if="columns.description">description</th>
            <th v-if="columns.iconPath">iconPath</th>
            <th v-if="columns.schematics">schematics</th>
            <th v-if="columns.products">products</th>
            <th v-if="columns.producers">producers</th>
            <th v-if="columns.children">children</th>
            <th v-if="columns.classId">classId</th>
            <th v-if="columns.displayClassId">displayClassId</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(i,ii) in paginate(filterItems())" :key="i.id" :class="getRowClassFromTier(i.tier)+ ' text-white'" style="--bs-bg-opacity:.6;">
            <td v-if="columns.id">
              <span class="btn btn-link p-0 m-0" title="Copy ID To Clipboard">
                <font-awesome-icon icon="copy" @click="copyToClipboard(i.id, 'ID')"/>
              </span>
              {{ i.id }}
            </td>
            <td v-if="columns.displayNameWithSize">
              <span class="btn btn-link p-0 m-0" title="Copy Name To Clipboard">
                <font-awesome-icon icon="copy" @click="copyToClipboard(i.displayNameWithSize, 'displayNameWithSize')"/>
              </span>
              {{ i.displayNameWithSize }}<br>
                <span class="btn btn-link p-0 m-0" title="Copy Name To Clipboard">
                <font-awesome-icon icon="copy" @click="copyToClipboard(i.locDisplayNameWithSize, 'locDisplayNameWithSize')"/>
              </span>
              {{ i.locDisplayNameWithSize }}<br>
                <span class="btn btn-link p-0 m-0" title="Copy Name To Clipboard">
                <font-awesome-icon icon="copy" @click="copyToClipboard(i.locDisplayNameWithSizeDE, 'locDisplayNameWithSizeDE')"/>
              </span>
              {{ i.locDisplayNameWithSizeDE }}
            </td>
            <td v-if="columns.tier">{{ i.tier }}</td>
            <td v-if="columns.size">{{ i.size }}</td>
            <td v-if="columns.unitMass">{{ numberWithSpaces(i.unitMass) }}</td>
            <td v-if="columns.unitVolume">{{ numberWithSpaces(i.unitVolume) }}</td>
            <td v-if="columns.description">{{ i.description }}</td>
            <td v-if="columns.iconPath">
              <template v-if="i.iconPath">
              <span class="btn btn-link p-0 m-0">
                <font-awesome-icon icon="copy" title="Copy ID To Clipboard" @click="copyToClipboard(i.iconPath, 'Path')"/>
              </span>
                {{ i.iconPath }}
              </template>
            </td>
            <td v-if="columns.schematics">
              <div v-for="(s,si) in i.schematics" :key="ii + '_schematics_' + si" :class="s.displayNameWithSize && s.displayNameWithSize.length > 0 ? '' : 'text-danger'">
                {{ s.displayNameWithSize }} // {{s.locDisplayNameWithSize }} // {{s.locDisplayNameWithSizeDE }} <i>({{ s.id }})</i>
              </div>
            </td>
            <td v-if="columns.products">
              <div v-for="(p,pi) in i.products" :key="ii + '_products_' + pi" :class="p.displayNameWithSize && p.displayNameWithSize.length > 0 ? '' : 'text-danger'">
                {{ p.displayNameWithSize || "UNKNOWN ITEM ID" }} <span v-if="p.locDisplayNameWithSize"> // {{p.locDisplayNameWithSize}}</span> <span v-if="p.locDisplayNameWithSizeDE"> // {{p.locDisplayNameWithSizeDE}}</span> <i>({{ p.id }})</i>
              </div>
            </td>
            <td v-if="columns.producers">
              <div v-for="(p,pi) in i.producers" :key="ii + '_products_' + pi" :class="p.displayNameWithSize && p.displayNameWithSize.length > 0 ? '' : 'text-danger'">
                {{ p.displayNameWithSize || "UNKNOWN ITEM ID" }} <span v-if="p.locDisplayNameWithSize"> // {{p.locDisplayNameWithSize}}</span> <span v-if="p.locDisplayNameWithSizeDE"> // {{p.locDisplayNameWithSizeDE}}</span> <i>({{ p.id }})</i>
              </div>
            </td>
            <td v-if="columns.children">
              <div v-for="(p,pi) in i.children" :key="ii + '_products_' + pi" :class="p.displayNameWithSize && p.displayNameWithSize.length > 0 ? '' : 'text-danger'">
                {{ p.displayNameWithSize || "UNKNOWN ITEM ID" }} <span v-if="p.locDisplayNameWithSize"> // {{p.locDisplayNameWithSize}}</span> <span v-if="p.locDisplayNameWithSizeDE"> // {{p.locDisplayNameWithSizeDE}}</span> <i>({{ p.id }})</i>
              </div>
            </td>
            <td v-if="columns.classId">
              <span class="btn btn-link p-0 m-0" title="Copy ID To Clipboard">
                <font-awesome-icon icon="copy" @click="copyToClipboard(i.classId, 'classId')"/>
              </span>
              {{ i.classId }}
            </td>
            <td v-if="columns.displayClassId">
              <span class="btn btn-link p-0 m-0" title="Copy ID To Clipboard">
                <font-awesome-icon icon="copy" @click="copyToClipboard(i.displayClassId, 'displayClassId')"/>
              </span>
              {{ i.displayClassId }}
            </td>
          </tr>
          </tbody>
          <tfoot>
          <d-u-item-list-pagination></d-u-item-list-pagination>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import * as toastr from 'toastr';
import DUItemListPagination from "@/components/DUItemListPagination";

const items_data = require('../data/items.json');

export default {
  name: "DUItemList",
  components: {DUItemListPagination},
  data() {
    return {
      items: [],
      searchText: '',
      loading: true,
      page: 1,
      byPage: 25,
      columns: {
        id: true,
        displayNameWithSize: true,
        tier: false,
        // type: false,
        unitMass: false,
        unitVolume: false,
        size: false,
        iconPath: false,
        description: false,
        schematics: false,
        products: false,
        producers: false,
        children: false,
        classId: false,
        displayClassId: false,
      },
      rowColorsOnTier: false,
      dropDownToggle: false,
    };
  },
  methods: {
    getRowClassFromTier(tier) {
      if (!this.rowColorsOnTier) return "";
      const classes = ["", "", "bg-success", "bg-info", "bg-purple", "bg-warning"];
      return classes[tier];
    },
    paginate(items) {
      if (this.page > 0) {
        const max_page = Math.ceil(this.filterItems().length / this.byPage)
        if (max_page > 0 && this.page > max_page) {
          this.page = max_page;
        }
        return items.slice((this.page - 1) * this.byPage, this.page * this.byPage + 1);
      }
      return [];
    },
    filterItems() {
      const searchable = this.searchText.toLowerCase().trim();
      if (searchable && searchable.length > 3) {
        return this.items.filter(i => (i.displayNameWithSize.toLowerCase().trim().includes(searchable) || (i.locDisplayNameWithSize && i.locDisplayNameWithSize.toLowerCase().trim().includes(searchable)) || (i.locDisplayNameWithSizeDE && i.locDisplayNameWithSizeDE.toLowerCase().trim().includes(searchable)) || (i.id && i.id.toString().trim().includes(searchable))));
      }
      return this.items;
    },
    copyToClipboard(data, name = 'ID') {
      navigator.clipboard.writeText(data).then(() => {
        toastr.success(name + " copied");
      }).catch(() => {
        toastr.danger('Failed to copy ' + name);
      })
    },
    numberWithSpaces(x) {
      let parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    },
    download(mode = "lua") {
      const items = this.filterItems();
      let items_to_download = []
      items.forEach(item => {
        let i = {
          id: item.id
        }
        if (this.columns.tier) i.tier = item.tier;
        if (this.columns.displayNameWithSize) {
          i.displayNameWithSize = item.displayNameWithSize;
          i.locDisplayNameWithSize = item.locDisplayNameWithSize;
          i.locDisplayNameWithSizeDE = item.locDisplayNameWithSizeDE;
        }
        if (this.columns.unitMass) i.unitMass = item.unitMass;
        if (this.columns.unitVolume) i.unitVolume = item.unitVolume;
        if (this.columns.size) i.size = item.size;
        if (this.columns.iconPath) i.iconPath = item.iconPath;
        if (this.columns.description) i.description = item.description;
        if (this.columns.schematics) i.schematics = item.schematics;
        if (this.columns.products) i.products = item.products;
        if (this.columns.producers) i.producers = item.producers;
        if (this.columns.children) {
          i.children = item.children;
          i.childIds = item.childIds;
        }
        if (this.columns.classId) i.classId = item.classId;
        if (this.columns.displayClassId) i.displayClassId = item.displayClassId;

        items_to_download.push(i);
      });

      let content = ""
      if (mode === 'json') {
        content = JSON.stringify(items_to_download);
      } else if (mode === 'lua') {
        let first = true;
        content = '_items={'
        items_to_download.forEach(item => {
          if (!first) {
            content += ',';
          }
          content += '["' + item.id + '"]={'
          let first_key = true;
          Object.keys(item).forEach(k => {
            if (!first_key) content += ',';
            if (typeof item[k] === 'object') {
              content += k + '={';
              let first_key_2 = true;
              item[k].forEach(i => {
                let first_key_3 = true;
                if (!first_key_2) content += ',';
                content += '{';
                Object.keys(i).forEach(k2 => {
                  if (!first_key_3) content += ',';
                  content += k2 + '=' + JSON.stringify(i[k2]);
                  first_key_3 = false;
                });
                content += '}';
                first_key_2 = false;
              });
              content += '}'
            } else {
              content += k + '=' + JSON.stringify(item[k]).replaceAll('[', '{').replaceAll(']', '}').replaceAll(':', '=');
            }
            if (first_key) first_key = false;
          });
          content += '}';
          if (first) {
            first = false;
          }
        });
        content += '}'
      }

      const filename = "items_api_dump." + mode;
      const file = new File([content], filename, {type: 'text/plain'});
      const elem = window.document.createElement('a');
      const url = window.URL.createObjectURL(file);
      elem.href = url;
      elem.download = file.name;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
      window.URL.revokeObjectURL(url)
    }
  },
  mounted() {
    this.items = items_data.sort((a, b) => (a.displayNameWithSize > b.displayNameWithSize) ? 1 : ((b.displayNameWithSize > a.displayNameWithSize) ? -1 : 0));
    for (let i = 0; i < this.items.length; i++) {
      this.items[i].products = [...new Set(this.items[i].products)];
    }
    this.loading = false;
  }
}
</script>

<style scoped>
</style>
