export default {
    getCompletions: function (editor, session, pos, prefix, callback) {
        const funcList = [
            "getRoll(gravityDirection, forward, right)",
            "getAxisAngleRad(oldDir, newDir, preferredAxis)",
            "systemResolution3(vec_c1,vec_c2,vec_c3,vec_c0)",
            "systemResolution2(vec_c1,vec_c2,vec_c0)",
        ];

        callback(null, [
                ...funcList.map(function (word) {
                    return {
                        caption: word,
                        value: word,
                        meta: "function"
                    };
                })
            ]
        );
    }
}
