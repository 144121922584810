module.exports = {
    code: '',
    filter: {
        args: [],
        signature: 'onCompleted()',
        sigDesc: '--for Plasma Extractor',
        slotKey: "-1",
    },
    key: "0"
}
