module.exports = {
    code: '',
    filter: {
        args: [
            {
                "value": "id"
            }
        ],
        signature: 'onStarted(id)',
        sigDesc: '--for Mining Unit',
        slotKey: "-1",
    },
    key: "0"
}
