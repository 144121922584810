<template>
  <div id="app" class="app">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  computed: {
    winHeight() {
      return window.innerHeight
    }
  }
}
</script>

<style>

.modal-backdrop {
  background: rgba(29, 40, 53, .75);
}

.btn {
  border-radius: 0;
  text-overflow: ellipsis;
}

.cursor-pointer {
  cursor: pointer;
}

.card {
  background-image: url("/img/logo-tl.png"), url("/img/logo-tr.png"), url("/img/logo-bl.png"), url("/img/logo-br.png");
  background-repeat: no-repeat;
  background-position: top left, top right, bottom left, bottom right;
  background-size: 35px;
}


::-webkit-scrollbar {
  width: 5px;
  /*border-radius: 10px;*/
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /*border-radius: 10px;*/
}

::-webkit-scrollbar-thumb {
  background: #3cd2a5;
  /*border-radius: 10px;*/
}

::-webkit-scrollbar-thumb:hover {
  background: #6DDDBC;
  cursor: grab;
}


</style>
