<template>
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="col">
        <h1>Unsupported Feature</h1>
        <p>
          What you are trying to do is not supported by the editor.
        </p>
        <p>
          If you have any feature request or anything you would like to do, join <a href="https://guilded.jericho.dev" target="_blank">https://guilded.jericho.dev</a> to discuss it.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnsupportedPage",
  mounted() {
    console._log("UnsupportedPage mounted");
  }
}
</script>

<style scoped>

</style>
