<template>
  <div>
    <div class="container-fluid mt-2">
      <div class="row">
        <div class="col">
          <b-button variant="success" @click="runLUA()">
            <font-awesome-icon icon="play"></font-awesome-icon>
            Run Script
          </b-button>
          <b-button style="margin-left: 15px;" variant="success" @click="minify()">
            <font-awesome-icon icon="minus"></font-awesome-icon>
            Minify Script
          </b-button>
        </div>
        <div class="col text-end">
          <b-button variant="danger" @click="code = ''">
            <font-awesome-icon icon="trash"></font-awesome-icon>
            Clear Editor
          </b-button>
        </div>
      </div>
      <div class="row">
        <small class="text-danger mb-0 pt-0">Warning, the emulator is using lua v5.3.x and du was updated to v5.4.x, some feature in DU may not be available here</small>
        <div id="editorContainer" :style="'height:' + ($parent.winHeight-325) + 'px;'" class="col mt-1">
          <ace_editor v-model="code" height="100%" lang="lua" theme="chaos" width="100%" @init="editorInit"></ace_editor>
        </div>
      </div>
      <div class="row">
        <div id="editorContainer2" class="col">
          <ace_editor v-model="output" height="100%" theme="chaos" wicth="100%" @init="editorInitOut"></ace_editor>
          <textarea id="console" style="display: none;"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

console._log = console.log;
console.log = function (logMessage, custom = true) {
  if (custom) {
    let console_result = document.getElementById('console');
    if (console_result) console_result.value = console_result.value + logMessage + '\n';
  } else {
    console._log(logMessage);
  }
};

import * as ace_editor from 'vue2-ace-editor'
import * as fengari from 'fengari-web'
import * as luamin from 'luamin'

export default {
  name: "luaEmulator",
  components: {
    ace_editor,
  },
  data() {
    return {
      code: '--demo code\nlocal message = "hello World !!"\nprint(message)\n\nlocal a = 5\nlocal b = 10\nprint(a + b)',
      output: '',
    };
  },
  methods: {
    editorInit(editor) {
      require('brace/ext/language_tools');
      require('brace/mode/lua');
      require('brace/snippets/lua');
      require('brace/theme/chaos');
      editor.setPrintMarginColumn(false);
      editor.setOption("wrap", true);
      editor.setOptions({
        highlightActiveLine: true,
        highlightSelectedWord: true,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        wrapBehavioursEnabled: true
      });
    },
    editorInitOut(editor) {
      require('brace/ext/language_tools');
      require('brace/snippets/lua');
      require('brace/theme/chaos');
      editor.setTheme("ace/theme/chaos");
      editor.setPrintMarginColumn(false);
      editor.setOption("wrap", true);
      editor.setOptions({
        highlightActiveLine: true,
        highlightSelectedWord: true,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        wrapBehavioursEnabled: true,
        readOnly: true
      });

    },
    async runLUA() {
      this.$gtag.event('run_script', {method: 'Google'});
      let console_result = document.getElementById('console');
      console_result.value = '';
      try {
        const result = fengari.load(this.code)();
        if (result) console_result.value += 'RETURN: ' + result + '\n';
      } catch (throw_error) {
        if (console_result) console_result.value += 'ERROR: ' + throw_error + '\n';
      } finally {
        this.output = console_result.value || "";
      }
    },
    minify() {
      this.code = luamin.minify(this.code);
    }
  },
  mounted() {
    this.output = '';
  }
}
</script>

<style scoped>
/*
 * LUA ONLINE EDITOR
 */

#editorContainer, #editorContainer2 {
  border: 3px solid rgba(0, 0, 0, .5);
  padding: 0;
}

#editorContainer2 {
  border-top: 0;
  min-height: 150px;
}
</style>
