<template>
  <div class="container-fluid mt-3">

  </div>
</template>

<script>
export default {
  name: "DUCraftCalculator"
}
</script>

<style scoped>

</style>
