module.exports = {
    code: '',
    filter: {
        args: [
            {
                "value": "channel"
            },
            {
                "value": "message"
            }
        ],
        signature: 'onReceived(channel, message)',
        slotKey: "-1",
    },
    key: "0"
}
