let {options} = require('./actionFilterOptions');

// console.log(options)
// let opts = Object.assign({}, options);

module.exports = {
    code: '',
    filter: {
        args: [
            {
                value: "",
                options: options,
            }
        ],
        signature: 'onActionLoop(action)',
        slotKey: "-1",
    },
    key: "0"
}
