<template>
  <div class="container mt-3">
    <div class="row">
      <div class="col">
        <div class="card mb-3 cursor-pointer" @click="$router.push('editor')">
          <div class="card-header text-white">
            <b>
              <font-awesome-icon icon="code"></font-awesome-icon>
              LUA EDITOR
            </b>
          </div>
          <div class="card-body">
            <img alt="editor screenshot" src="/img/editor_screen.png" width="100%">
          </div>
          <div class="card-footer">
            An online lua editor for Dual Universe
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card cursor-pointer" @click="$router.push('/emulator')">
          <div class="card-header text-white">
            <span class="badge alert-warning float-end">Beta</span>
            <b>
              <font-awesome-icon icon="play"></font-awesome-icon>
              LUA EMULATOR
            </b>
          </div>
          <div class="card-body">
            <img alt="emulator screenshot" src="/img/emulator_screen.png" width="100%">
          </div>
          <div class="card-footer">
            Run, test and minify Lua scripts here
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card cursor-pointer" @click="$router.push('/codex')">
          <div class="card-header text-white">
            <b>
              <font-awesome-icon icon="book"></font-awesome-icon>
              LUA CODEX
            </b>
          </div>
          <div class="card-body">
            <img src="/img/du_codex_screen.png" style="width: 100%;">
          </div>
          <div class="card-footer">
            Lua Api Documentation
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card cursor-pointer" @click="$router.push('/discord')">
          <div class="card-header text-white">
            <b>
              <font-awesome-icon :icon="['fab', 'discord']"/>
              Discord Bot
            </b>
          </div>
          <div class="card-body">
            <img alt="emulator screenshot" src="/img/discord_screen.png" width="100%">
            <br>&nbsp;
          </div>
          <div class="card-footer">
            Run, test and minify Lua scripts on discord
          </div>
        </div>
      </div>

    </div>
    <div class="row mt-5">
      <div class="col">
        <div class="card cursor-pointer" @click="$router.push('/utils')">
          <div class="card-header text-white">
            <b>
              <font-awesome-icon :icon="code"/>
              Utilities
            </b>
          </div>
          <div class="card-body">
            <img alt="Utilities" src="/img/utils_screen.png" width="100%">
            <br>
            &nbsp;
          </div>
          <div class="card-footer">
            This page contains utilities like a color picker
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card cursor-pointer" @click="goToOfficialLuaHelpRepo()">
          <div class="card-header text-white">
            <b>
              <font-awesome-icon :icon="['fab', 'github']"/>
              Official Github Repo - Lua Examples
            </b>
          </div>
          <div class="card-body">
            <img alt="dual-universe/lua-examples" src="/img/screen_du_help_repo.jpg" width="100%">
          </div>
          <div class="card-footer">
            This repository has been created to serve as a sharing and learning platform for the Dual Universe community and the development of simple and complex Lua applications.
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card cursor-pointer" @click="$router.push('/items')">
          <div class="card-header text-white">
            <b>
              <font-awesome-icon icon="book"></font-awesome-icon>
              Items API Dump
            </b>
          </div>
          <div class="card-body">
            <img alt="dual-universe/lua-examples" src="/img/du_item_list.png" width="100%">
            <br>&nbsp;
          </div>
          <div class="card-footer">
            The list of items data extracted from the game api.
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card cursor-pointer" @click="$router.push('/recipes')">
          <div class="card-header text-white">
            <b>
              <font-awesome-icon icon="book"></font-awesome-icon>
              Recipes API Dump
            </b>
          </div>
          <div class="card-body">
            <img alt="dual-universe/lua-examples" src="/img/du_recipe_list.png" width="100%">
            <br>&nbsp;
          </div>
          <div class="card-footer">
            The list of recipes data extracted from the game api.
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "Home",
  methods: {
    goToOfficialLuaHelpRepo() {
      window.open('https://github.com/dual-universe/lua-examples', '_blank');
    }
  }
}
</script>

<style scoped>
</style>
