export default {
    getCompletions: function (editor, session, pos, prefix, callback) {
        const list = {
            "database": [
                "getPlayer(id)",
                "getMasterPlayer(unit)",
                "getOrganization(id)",
                "getConstruct(radar, id)",
                "getElement(core, id)"
            ]
        };


        let autocomplete = [];

        Object.entries(list).forEach(([li, l]) => {
            autocomplete.push({
                caption: li,
                value: l,
                meta: "keyword"
            });
            l.forEach(fn => {
                autocomplete.push({
                    caption: `${li}.${fn}`,
                    value: `${li}.${fn}`,
                    meta: "function"
                });
                autocomplete.push({
                    caption: fn,
                    value: fn,
                    meta: "function"
                });
            })
        });

        callback(null, autocomplete);
    }
}
