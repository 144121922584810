export default {
    getCompletions: function (editor, session, pos, prefix, callback) {
        // const generic = [
        //     "show()",
        //     "hide()",
        //     "getData()",
        //     "getDataId()",
        //     "getWidgetType()",
        //     "getIntegrity()",
        //     "getHitPoints()",
        //     "getMaxHitPoints()",
        //     "getId()",
        //     "getMass()",
        //     "getElementClass()",
        //     "getRemainingRestorations()",
        //     "getMaxRestorations()",
        //     "setSignalIn(plug, state)",
        //     "getSignalIn(plug)",
        //     "getSignalOut(plug)",
        // ];

        const list = require('./autocomplete.json');

        let autocomplete = [];

        Object.entries(list).forEach(([li, l]) => {
            autocomplete.push({
                caption: li,
                value: l,
                meta: "keyword"
            });
            l.forEach(fn => {
                autocomplete.push({
                    caption: `${li}.${fn}`,
                    value: `${li}.${fn}`,
                    meta: "function"
                });
                autocomplete.push({
                    caption: fn,
                    value: fn,
                    meta: "function"
                });
            });
        });

        callback(null, autocomplete);
    }
}
