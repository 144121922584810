<template>
  <div class="container-fluid mt-3 mb-5">
    <div class="row">
      <div class="col-2">
        <div class="input-group">
          <input v-model="codex_search" class="form-control" placeholder="Search a category" type="text">
          <div class="input-group-append">
            <button class="btn btn-danger" @click="codex_search=''">
              <font-awesome-icon icon="times"></font-awesome-icon>
            </button>
          </div>
        </div>
      </div>
      <div v-if="selected_category_index" class="col">
        <div class="input-group">
          <input v-model="method_search" class="form-control" placeholder="Search a method" type="text">
          <div class="input-group-append">
            <button class="btn btn-danger" @click="method_search=''">
              <font-awesome-icon icon="times"></font-awesome-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">&nbsp;</div>
    </div>
    <div class="row">
      <div :style="'overflow-y: auto; max-height: ' + maxHeight + 'px;'" class="col-2">
        <div v-for="(category, ci) in Object.keys(filterCodex())" :key="'category_'+ci" class="mb-1" @click="selected_category_index = category">
          <button :class="selected_category_index === category ? 'btn btn-primary w-100 border border-2 border-warning catButton' : 'btn btn-primary w-100 catButton'">
            {{ category }}
          </button>
        </div>
      </div>
      <div :class="selected_category_index === null ? 'col my-auto': 'col'" :style="'overflow-y: auto; max-height: ' + maxHeight + 'px;'">
        <div v-if="selected_category_index === null" class="text-center">
          Select a category
          <br><br>
          The codex is generated procedurally, based on the api mockups on the official Dual Universe Github repository.
          <br><br>
          If you find any mistake of error, please, report it on my <a href="https://guilded.jericho.dev">Guilded Server</a>.
        </div>
        <div v-else-if="codex[selected_category_index]">
          <div class="catButton">
            <h4>{{selected_category_index}}</h4>
            <br>
          </div>
          <div v-for="(method, mi) in filterMethods(selected_category)" :key="'category_' + selected_category_index + '_method_' + mi"
               :class="method.deprecated.value ? 'card mb-3 text-white' : 'card mb-3 text-white'">
            <div class="card-header text-white cursor-pointer" @click="method.show_content = !method.show_content">
              <b>
                <font-awesome-icon v-show="!method.show_content" icon="chevron-right"></font-awesome-icon>
                <font-awesome-icon v-show="method.show_content" icon="chevron-down"></font-awesome-icon>
                {{ method.signature }} <span v-if="method.deprecated.value" class="text-danger fw-bolder">(deprecated) {{ method.deprecated.comment }}</span>
              </b>
            </div>
            <div v-if="method.show_content" class="card-body">
              <i>{{ method.description }}</i>
              <template v-if="method.arguments.length > 0">
                <hr>
                <b>Arguments</b>
                <lua-codex-method-args-table :args="method.arguments" :key_base="'category_' + selected_category_index + '_method_' + mi + '_args_'"></lua-codex-method-args-table>
              </template>
              <template v-if="method.returns.length > 0">
                <hr>
                <b>Returns</b>
                <lua-codex-method-returns-table :args="method.returns" :key_base="'category_' + selected_category_index + '_method_' + mi + '_returns_'"></lua-codex-method-returns-table>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LuaCodexMethodArgsTable from "@/components/luaCodexMethodArgsTable";
import LuaCodexMethodReturnsTable from "@/components/luaCodexMethodReturnsTable";

const CodexData = require('@/data/codex.json');

export default {
  name: "luaCodex",
  components: {LuaCodexMethodArgsTable, LuaCodexMethodReturnsTable},
  data() {
    return {
      selected_category_index: null,
      codex: {},
      codex_search: '',
      method_search: '',
    };
  },
  methods: {
    filterCodex() {
      if (this.codex_search.length <= 3) {
        return this.codex;
      }
      let new_codex = {};
      Object.keys(this.codex).forEach(category => {
        if (category.toLowerCase().trim().includes(this.codex_search.toLowerCase().trim())) {
          new_codex[category] = this.codex[category];
        }
      });
      return new_codex;
    },
    filterMethods(methods) {
      if (this.method_search.length < 3) {
        return methods;
      }
      let new_methods = [];
      methods.forEach(method => {
        if (method.signature.toLowerCase().trim().includes(this.method_search.toLowerCase().trim())) {
          new_methods.push(method);
        }
      });
      return new_methods;
    }
  },
  computed: {
    selected_category() {
      console.log(this.selected_category_index);
      console.log(this.codex[this.selected_category_index]);
      return this.codex[this.selected_category_index];
    },
    maxHeight() {
      return window.innerHeight - 200;
    }
  },
  mounted() {
    this.codex = CodexData;
    //order this.codex by key name
    this.codex = Object.keys(this.codex).sort().reduce((result, key) => {
      result[key] = this.codex[key];
      return result;
    }, {});
    console._log(this.codex);
  }
}
</script>

<style scoped>
.card {
  background-color: rgba(0, 0, 0, .25);
}

.catButton::first-letter {
  text-transform: capitalize;
}
</style>
