<template>
  <div>
    <div class="">
      <div class="card-body">
        <div v-if="codeData===null" class="row">
          <div class="col">
            <div class="row">
              <div class="col mb-2">
                <b-dropdown :id="`slot_dropdown_default_scripts`" text="Start a new project" variant="primary">
                  <template v-for="(script, si) in default_scripts">
                    <b-dropdown-divider v-if="!script.source" :key="'script_'+si"></b-dropdown-divider>
                    <b-dropdown-item v-else :key="'script_'+si" @click="load_script(script.source)">
                      <font-awesome-icon icon="code"></font-awesome-icon>
                      {{ script.label }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
                or Copy and Paste your JSON export here
              </div>
              <div class="col text-right">
              </div>
            </div>
            <div class="row">
              <div class="col p-0" style="border:3px solid rgba(0,0,0,.5);">
                <ace_editor v-model="json" :height="($parent.winHeight - 240)" lang="json" theme="chaos" width="100%" @init="editorInitJSON"></ace_editor>
              </div>
            </div>
          </div>
        </div>
        <template v-if="codeData === null">
          <div class="row">
            <div class="col mt-2">
              <button :disabled="json.length === 0" class="btn btn-success mt-1" @click="convertJSON()">
                <font-awesome-icon icon="sign-in-alt"></font-awesome-icon>
                Load JSON to editor
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col">
              &nbsp;<br>
            </div>
          </div>
        </template>
        <div v-if="codeData" class="row">
          <div class="col-2">
            <div class="row">
              <div :style="'height: '+($parent.winHeight - 250)+'px; overflow: auto;'" class="col">
                <div v-for="(slot, slot_index) in sortObjectByKeysNumber(codeData)" :key="slot.name" class="row m-1">
                  <div class="col d-grid">
                    <div v-if="rename_slot_index !== getRealSlotIndex(slot_index)"
                         :class="getRealSlotIndex(slot_index) === selected_slot_index ? 'btn-group border border-2 border-warning' : 'btn-group'">
                      <b-button variant="primary" @click="selectSlot(getRealSlotIndex(slot_index))">
                        {{ codeData[getRealSlotIndex(slot_index)].name }}
                      </b-button>
                      <b-dropdown v-if="getRealSlotIndex(slot_index) >=0" :id="`slot_dropdown_${getRealSlotIndex(slot_index)}`" variant="primary">
                        <b-dropdown-item @click="enableRenameSlot(getRealSlotIndex(slot_index))">
                          <font-awesome-icon icon="edit"></font-awesome-icon>
                          Rename Slot
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                    <div v-else>
                      <b-input-group>
                        <b-form-input v-model="rename_slot_value" placeholder="Slot Name"
                                      @keydown.esc="rename_slot_index = null" @keydown.enter="submitSlotName(getRealSlotIndex(slot_index))"></b-form-input>
                        <b-button v-b-tooltip.hover title="Enter" variant="success" @click="submitSlotName(getRealSlotIndex(slot_index))">
                          <font-awesome-icon icon="check"></font-awesome-icon>
                        </b-button>
                        <b-button v-b-tooltip.hover title="Escape" variant="danger" @click="rename_slot_index = null">
                          <font-awesome-icon icon="times"></font-awesome-icon>
                        </b-button>
                      </b-input-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div v-if="codeData" class="row m-1">
                  <div class="col d-grid gap-2">
                    <button class="ml-5 btn btn-sm btn-success" @click="buildJsonDataAndExit()">
                      <font-awesome-icon icon="sign-out-alt"></font-awesome-icon>
                      Build JSON and Exit
                    </button>
                    <button class="ml-5 btn btn-sm btn-success" @click="copyToClipboard()">
                      <font-awesome-icon icon="copy"></font-awesome-icon>
                      Copy JSON to clipboard
                    </button>
                    <button class="ml-5 btn btn-sm btn-success" @click="copyToClipboard(true)">
                      <font-awesome-icon icon="copy"></font-awesome-icon>
                      Minify and Copy JSON to clipboard
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <template v-if="selected_slot_index">
              <div class="row">
                <div class="col d-grid gap-2">
                  <b-input-group>
                    <b-form-input v-model="handlersSearchText" placeholder="Search" size="sm" @keydown.esc="handlersSearchText = ''"></b-form-input>
                    <b-button v-b-tooltip.hover title="Escape" variant="danger" @click="handlersSearchText = ''">
                      <font-awesome-icon icon="times"></font-awesome-icon>
                    </b-button>
                  </b-input-group>
                </div>
              </div>
              <div v-if="getFiltersForSeletedSlot().length > 0" class="row">
                <div class="col d-grid gap-2">
                  <b-dropdown id="dropdown_add_filter" class="mt-1" size="sm" text="Add Filter" variant="success">
                    <b-dropdown-item v-for="filter in getFiltersForSeletedSlot()" :key="'filter_add_' + filter.filter.signature" @click="addFilterToSlot(filter)">
                      {{ filter.filter.signature }} {{ filter.filter.sigDesc || "" }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="row">
                <div :style="'height: '+($parent.winHeight - 215)+'px; overflow: auto;'" class="col">
                  <div v-for="method in codeData[selected_slot_index].type.methods" :key="codeData[selected_slot_index].name + '.' + method.name" class="row">
                    <div class="col">
                      method.name
                    </div>
                  </div>
                  <div v-for="event in codeData[selected_slot_index].type.events" :key="codeData[selected_slot_index].name + '.' + event.name" class="row">
                    <div class="col">
                      {{ event.name }}
                    </div>
                  </div>
                  <template v-for="(handler, handler_index) in codeData[selected_slot_index].handlers">
                    <div v-if="getHandlerName(handler).includes(handlersSearchText)" :key="codeData[selected_slot_index].name + '.' + handler.filter.signature + '.' + handler_index"
                         class="row">
                      <div class="col d-grid gap-2">
                        <b-button-group class="m-1">
                          <b-button :class="handler_index === selected_handler_index ? 'border-2 border-warning' : ''" size="sm" variant="info"
                                    @click="selected_handler_index = handler_index">
                            {{ getHandlerName(handler) }}
                          </b-button>
                          <b-dropdown :id="`handler_dropdown_${handler_index}`" size="sm" variant="info">
                            <b-dropdown-item v-if="handler.filter.args.length > 0" variant="warning" @click="editFilterInSlot(handler)">
                              <font-awesome-icon icon="edit"></font-awesome-icon>
                              Edit Arguments
                            </b-dropdown-item>
                            <b-dropdown-item v-b-modal.modal-delete-filter variant="danger" @click="selected_handler_index_for_remove = handler_index">
                              <font-awesome-icon icon="trash"></font-awesome-icon>
                              Delete Filter
                            </b-dropdown-item>
                          </b-dropdown>
                        </b-button-group>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div>
                <b-modal id="modal-delete-filter" :hide-footer="true" :hide-header-close="true" body-bg-variant="dark" header-bg-variant="dark" header-close-content="X"
                         title="BootstrapVue">
                  <template #modal-title>
                    Do you really want to remove the filter <code>{{ getHandlerName(codeData[selected_slot_index].handlers[selected_handler_index_for_remove]) }}</code>?
                  </template>
                  <template #default>
                    <div class="row">
                      <div class="col">
                        <b-button size="sm" variant="success" @click="deleteFilter()">
                          <font-awesome-icon icon="check"></font-awesome-icon>
                          Remove
                        </b-button>
                      </div>
                      <div class="col text-end">
                        <b-button size="sm" variant="danger" @click="$bvModal.hide('modal-delete-filter')">
                          <font-awesome-icon icon="times"></font-awesome-icon>
                          Cancel
                        </b-button>
                      </div>
                    </div>
                  </template>
                </b-modal>
              </div>
              <div>
                <b-modal id="modal-add-filter" :hide-footer="true" :hide-header-close="true" body-bg-variant="dark" header-bg-variant="dark" header-close-content="X"
                         title="BootstrapVue">
                  <template #modal-title>
                    Do you really want to add the filter <code>{{ selected_filter_to_add.filter.signature }}</code>?
                  </template>
                  <template #default>
                    <div v-for="(arg, index) in selected_filter_to_add.filter.args" :key="'arg_' + index" class="row">
                      <div v-if="arg.options" class="col">
                        <v-select v-model="args[index].value" :label="arg.value" :options="arg.options" :placeholder="arg.value" class="bg-light rounded text-dark"></v-select>
                        <br>
                      </div>
                      <div v-else class="col">
                        <input v-model="args[index].value" :placeholder="arg.value" class="form-control" type="text">
                        <br>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <b-button :disabled="!argsNotEmpty(selected_filter_to_add.filter.args.length)" size="sm" variant="success"
                                  @click="addFilterToSlot(selected_filter_to_add, false)">
                          <font-awesome-icon icon="check"></font-awesome-icon>
                          Add
                        </b-button>
                      </div>
                      <div class="col text-end">
                        <b-button size="sm" variant="danger" @click="$bvModal.hide('modal-add-filter')">
                          <font-awesome-icon icon="times"></font-awesome-icon>
                          Cancel
                        </b-button>
                      </div>
                    </div>
                  </template>
                </b-modal>
              </div>
              <div>
                <b-modal id="modal-edit-filter" :hide-footer="true" :hide-header-close="true" body-bg-variant="dark" header-bg-variant="dark" header-close-content="X" title="BootstrapVue">
                  <template #modal-title>
                    Do you really want to change the arguments of <code>{{ getHandlerName(selected_handler_for_edit) }}</code>?
                  </template>
                  <template #default>
                    <div v-for="(arg, index) in selected_filter_to_add.filter.args" :key="'arg_' + index" class="row">
                      <div v-if="arg.options" class="col">
                        <v-select v-model="args[index].value" :label="arg.value" :options="arg.options" :placeholder="arg.value" class="bg-light rounded text-dark"></v-select>
                        <br>
                      </div>
                      <div v-else class="col">
                        <input v-model="args[index].value" :placeholder="arg.value" class="form-control" type="text">
                        <br>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <b-button :disabled="!argsNotEmpty(selected_filter_to_add.filter.args.length)" size="sm" variant="success"
                                  @click="editFilterInSlot(selected_handler_for_edit, false)">
                          <font-awesome-icon icon="check"></font-awesome-icon>
                          Apply
                        </b-button>
                      </div>
                      <div class="col text-end">
                        <b-button size="sm" variant="danger" @click="$bvModal.hide('modal-edit-filter')">
                          <font-awesome-icon icon="times"></font-awesome-icon>
                          Cancel
                        </b-button>
                      </div>
                    </div>
                  </template>
                </b-modal>
              </div>

            </template>
          </div>
          <div v-if="selected_handler_index !== null" id="editorContainer" class="col">
            <template v-if="codeData[selected_slot_index]?.handlers[selected_handler_index]">
              <ace_editor v-model="codeData[selected_slot_index].handlers[selected_handler_index].code" :height="($parent.winHeight-145)" lang="lua" theme="chaos" width="100%"
                          @init="editorInit"></ace_editor>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-if="codeData" style="height: 45px;"></div>
  </div>
</template>

<script>

import * as ace_editor from 'vue2-ace-editor';
import * as toastr from 'toastr';
import database from "@/editor/autocompletion/database";
import dkjson from "@/editor/autocompletion/dkjson";
import helpers from "@/editor/autocompletion/helpers";
import axisCommand from "@/editor/autocompletion/axisCommand";
import Navigator from "@/editor/autocompletion/Navigator";
import elementsApi from "@/editor/autocompletion/elementsApi";
import filters from "@/editor/filters";
import axios from "axios";
import * as luamin from 'luamin'

export default {
  name: "duLuaEditor",
  components: {
    ace_editor,
  },
  data() {
    return {
      json: "",
      codeData: null,
      selected_slot_index: null,
      selected_handler_index: null,
      selected_handler_index_for_remove: null,
      selected_handler_for_edit: null,
      selected_filter_to_add: null,
      args: [
        {value: ""},
        {value: ""},
        {value: ""},
      ],
      rename_slot_index: null,
      rename_slot_value: "",
      handlersSearchText: "",
      default_scripts: [
        {
          label: 'Programming Board : Default empty script',
          source: 'https://raw.githubusercontent.com/Jericho1060/du-default-autoconf/main/json/programming_board/empty.json'
        },
        {
          label: '---',
        },
        {
          label: 'Emergency Control Unit : Default ECU Script',
          source: 'https://raw.githubusercontent.com/Jericho1060/du-default-autoconf/main/json/ecu/default.json'
        },
        {
          label: '---',
        },
        {
          label: 'Gunner Module : Default PVP Seat Script',
          source: 'https://raw.githubusercontent.com/Jericho1060/du-default-autoconf/main/json/gunner_module/default.json'
        },
        {
          label: '---',
        },
        {
          label: 'Hover Seat : Default Ground Construct Script',
          source: 'https://raw.githubusercontent.com/Jericho1060/du-default-autoconf/main/json/hover_seat/ground.json'
        },
        {
          label: 'Hover Seat : Default Flying Construct Script',
          source: 'https://raw.githubusercontent.com/Jericho1060/du-default-autoconf/main/json/hover_seat/flying.json'
        },
        {
          label: '---',
        },
        {
          label: 'Pilot Seat : Default Ground Construct Script',
          source: 'https://raw.githubusercontent.com/Jericho1060/du-default-autoconf/main/json/pilot_seat/ground.json'
        },
        {
          label: 'Pilot Seat : Default Flying Construct Script',
          source: 'https://raw.githubusercontent.com/Jericho1060/du-default-autoconf/main/json/pilot_seat/flying.json'
        },
        {
          label: '---',
        },
        {
          label: 'Remote Control : Default Ground Construct Script',
          source: 'https://raw.githubusercontent.com/Jericho1060/du-default-autoconf/main/json/remote_control/ground.json'
        },
        {
          label: 'Remote Control : Default Flying Construct Script',
          source: 'https://raw.githubusercontent.com/Jericho1060/du-default-autoconf/main/json/remote_control/flying.json'
        },
      ],
    };
  },
  async mounted() {
    if (this.$route.params.user && this.$route.params.repo) {
      try {
        await this.load_script('https://raw.githubusercontent.com/' + this.$route.params.user + '/' + this.$route.params.repo + '/main/config.json')
        this.selected_slot_index = -1;
        this.selected_handler_index = 0;
      } catch (e) {
        toastr.error("config.json not found at the root of the repository");
      }
    }
  },
  methods: {
    async load_script(src) {
      const response = await axios.get(src);
      this.json = JSON.stringify(response.data);
      this.convertJSON();
    },
    argsNotEmpty(numberOfArgs = 1) {
      if (numberOfArgs > 0) {
        let score = 1;
        for (let i = 0; i < numberOfArgs; i++) {
          let v = 0;
          if (this.args[i].value) {
            v = this.args[i].value.length;
          }
          score *= v;
        }
        return score > 0;
      }
      return true;
    },
    log(message) {
      console._log(message);
    },
    addFilterToSlot(filter, checkArgs = true) {
      filter = JSON.parse(JSON.stringify(filter));
      if (filter.filter.args.length === 0 || !checkArgs) {
        if (filter.filter.args.length > 0) {
          let args = [];
          const argsValues = JSON.parse(JSON.stringify(this.args));
          for (let index = 0; index < filter.filter.args.length; index++) {
            const v = argsValues[index].value.toString();
            if (v === "*") {
              args.push({variable: JSON.parse(JSON.stringify(v))});
            } else {
              args.push({value: JSON.parse(JSON.stringify(v))});
            }
          }
          filter.filter.args = args;
        }
        filter.filter.slotKey = this.getRealSlotIndex(this.selected_slot_index).toString();
        this.codeData[this.selected_slot_index].handlers.push(filter);
        this.$bvModal.hide('modal-add-filter');
      } else {
        this.selected_filter_to_add = filter;
        for (let index = 0; index < filter.filter.args.length; index++) {
          this.args[index].value = "";
        }
        this.$bvModal.show('modal-add-filter');
      }
    },
    editFilterInSlot(handler, checkArgs = true) {
      this.selected_handler_for_edit = handler;
      const filters_for_slots = JSON.parse(JSON.stringify(this.getFiltersForSeletedSlot()));
      let filter = filters_for_slots.filter(f => f.filter.signature.split('(')[0] === handler.filter.signature.split('(')[0])[0];
      filter = JSON.parse(JSON.stringify(filter));
      if (!checkArgs) {
        let args = [];
        const argsValues = JSON.parse(JSON.stringify(this.args));
        for (let index = 0; index < filter.filter.args.length; index++) {
          const v = argsValues[index].value.toString();
          if (v === "*") {
            args.push({variable: JSON.parse(JSON.stringify(v))});
          } else {
            args.push({value: JSON.parse(JSON.stringify(v))});
          }
        }
        handler.filter.args = args;
        // filter.filter.slotKey = this.getRealSlotIndex(this.selected_slot_index).toString();
        // this.codeData[this.selected_slot_index].handlers[handler_index] = handler;
        this.$bvModal.hide('modal-edit-filter');
      } else {
        this.selected_filter_to_add = filter;
        for (let index = 0; index < filter.filter.args.length; index++) {
          if (handler.filter.args[index].variable) {
            this.args[index].value = JSON.parse(JSON.stringify(handler.filter.args[index].variable));
          } else {
            this.args[index].value = JSON.parse(JSON.stringify(handler.filter.args[index].value));
          }
        }
        this.$bvModal.show('modal-edit-filter');
      }
    },
    getFiltersForSeletedSlot() {
      if (this.selected_slot_index) {
        return filters[this.selected_slot_index];
      }
      return [];
    },
    sortObjectByKeys(o) {
      return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
    },
    sortObjectByKeysNumber(o) {
      const ordered_keys = Object.keys(o).sort((a, b) => {
        return parseInt(a) - parseInt(b);
      });
      const obj = {};
      ordered_keys.forEach(k => {
        obj[k.toString() + "-keyplaceholder-"] = o[k.toString()];
      });
      return obj;
    },
    getHandlerName(handler) {
      if (handler) {
        let name = handler.filter.signature.split('(')[0] + '(';
        for (let i = 0; i < handler.filter.args.length; i++) {
          if (handler.filter.args[i].variable) {
            name += handler.filter.args[i].variable;
          } else {
            name += handler.filter.args[i].value;
          }
          if (i < handler.filter.args.length - 1) name += ", ";
        }
        return name + ')';
      }
      return "";
    },
    handlersSearch(handlers) {
      return handlers.filter(a => this.getHandlerName(a).includes(this.handlersSearchText))
    },
    deleteFilter() {
      this.$bvModal.hide('modal-delete-filter');
      if (this.selected_handler_index === this.selected_handler_index_for_remove) {
        this.selected_handler_index = null;
      }
      if (this.selected_handler_index > this.selected_handler_index_for_remove) {
        this.selected_handler_index--;
      }
      this.codeData[this.selected_slot_index].handlers.splice(this.selected_handler_index_for_remove, 1);
      this.selected_handler_index_for_remove = null;
    },
    getRealSlotIndex(index) {
      return index.replace('-keyplaceholder-', '');
    },
    enableRenameSlot(index) {
      this.rename_slot_index = index;
      this.rename_slot_value = this.codeData[index].name;
    },
    submitSlotName(index) {
      this.rename_slot_index = null;
      this.codeData[index].name = this.rename_slot_value;
    },
    convertJSON() {
      this.selected_slot_index = null;
      this.selected_handler_index = null;
      this.codeData = null;
      try {
        const parsed = JSON.parse(this.json);
        let slots = this.sortObjectByKeys(parsed.slots);
        for (const key in slots) {
          slots[key].handlers = [];
        }
        parsed.handlers.forEach(h => {
          slots[h.filter.slotKey].handlers.push(h);
        });
        for (const key in slots) {
          slots[key].handlers = slots[key].handlers.sort((a, b) => {
            if (this.getHandlerName(a) < this.getHandlerName(b)) {
              return -1;
            }
            if (this.getHandlerName(a) > this.getHandlerName(b)) {
              return 1;
            }
            return 0;
          })
        }
        this.codeData = slots;
        this.$gtag.event('load_json', {method: 'Google'});
      } catch (e) {
        toastr.error("Invalid JSON data");
      }
    },
    selectSlot(slot_index) {
      this.selected_handler_index = null;
      this.selected_slot_index = slot_index;
    },
    editorInit(editor) {
      require('brace/ext/language_tools');
      require('brace/mode/lua');
      require('brace/snippets/lua');
      require('brace/theme/chaos');
      editor.setPrintMarginColumn(false);
      editor.setOption("wrap", true);
      editor.setOptions({
        highlightActiveLine: true,
        highlightSelectedWord: true,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        wrapBehavioursEnabled: true
      });
      editor.completers.push(database);
      editor.completers.push(helpers);
      editor.completers.push(dkjson);
      editor.completers.push(axisCommand);
      editor.completers.push(Navigator);
      editor.completers.push(elementsApi);
    },
    editorInitJSON(editor) {
      require('brace/ext/language_tools');
      require('brace/mode/json');
      require('brace/snippets/json');
      require('brace/theme/chaos');
      editor.setPrintMarginColumn(false);
      editor.setOption("wrap", true);
      editor.setOptions({
        highlightActiveLine: true,
        highlightSelectedWord: true,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        wrapBehavioursEnabled: true
      });
    },
    buildJsonData(minify = false) {
      let JSON_obj = {
        events: [],
        handlers: [],
        methods: [],
        slots: {}
      };
      let indexes = [];
      for (let i = -5; i <= 20; i++) {
        indexes.push(i.toString());
      }
      indexes.forEach(index => {
        if (this.codeData[index]) {
          const slot = {...this.codeData[index]};
          if (slot.handlers) {
            slot.handlers.reverse().forEach(handler => {
              if (handler.code) {
                JSON_obj.handlers.push({...handler, code: minify ? luamin.minify(handler.code) : handler.code});
              }
            });
            delete slot.handlers;
          }
          JSON_obj.slots[index] = slot;
        }
      });
      return JSON.stringify(JSON_obj);
    },
    buildJsonDataAndExit() {
      this.json = this.buildJsonData();
      this.codeData = null;
      this.$gtag.event('build_json', {method: 'Google'});
    },
    copyToClipboard(minify = false) {
      const toCopy = this.buildJsonData(minify);
      navigator.clipboard.writeText(toCopy).then(() => {
        toastr.success("JSON copied");
      }).catch(() => {
        toastr.danger('Failed to copy JSON');
      });
      this.json = this.buildJsonData();
      const selected_slot_index = this.selected_slot_index;
      const selected_handler_index = this.selected_handler_index;
      this.convertJSON();
      this.selected_slot_index = selected_slot_index;
      this.selected_handler_index = selected_handler_index;
    }
  }
}
</script>

<style scoped>
#editorContainer, #editorContainer1 {
  border: 3px solid rgba(0, 0, 0, .5);
  padding: 0;
}

.dropdown-menu-scroll {
  max-height: 280px !important;
  overflow-y: auto !important;
}
</style>
