module.exports = {
    options: [
        "forward",
        "backward",
        "yawleft",
        "yawright",
        "strafeleft",
        "straferight",
        "left",
        "right",
        "up",
        "down",
        "groundaltitudeup",
        "groundaltitudedown",
        "lalt",
        "lshift",
        "gear",
        "light",
        "brake",
        "option1",
        "option2",
        "option3",
        "option4",
        "option5",
        "option6",
        "option7",
        "option8",
        "option9",
        "option10",
        "option11",
        "option12",
        "option13",
        "option14",
        "option15",
        "option16",
        "option17",
        "option18",
        "option19",
        "option20",
        "option21",
        "option22",
        "option23",
        "option24",
        "option25",
        "option26",
        "option27",
        "option28",
        "option29",
        "leftmouse",
        "stopengines",
        "speedup",
        "speeddown",
        "antigravity",
        "booster",
        "*",
    ],
}
