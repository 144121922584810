<template>
  <footer class="fixed-bottom app-footer text-white" style="padding: 10px;">
    <div class="row">
      <div class="col-2 text-left">
        <span style="margin-left: 25px;">&copy; {{ getYear() }} <a href="https://jericho.dev" target="_blank">Jericho</a> - Version {{ v }}</span>
      </div>
      <div class="col text-center">
        <a href="https://www.guilded.gg/Jerichos-Ressources/blog/du-luadev-patch-notes" target="_blank">Patch Notes</a> | <a href="https://guilded.jericho.dev" target="_blank">Support,
        help, feature requests, ...</a>
        <br>
        Dual Universe is a trademark of Novaquark SAS. du-lua.dev is not affiliated with or endorsed by Novaquark SAS. Used with permission, without endorsement.
      </div>
      <div class="col-2 text-end">
        If you like it
        <a href='https://ko-fi.com/I2I3L0X7' style="margin-right: 25px;" target='_blank'>
          <img alt='Buy Me a Coffee at ko-fi.com' src='https://cdn.ko-fi.com/cdn/kofi1.png?v=3' style='border:0;height:25px;'/>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import {version} from '../../package';

export default {
  name: "Footer",
  data() {
    return {v: version};
  },
  methods: {
    getYear() {
      return (new Date()).getFullYear()
    }
  }
}
</script>

<style scoped>
footer {
  border-top: 3px solid rgba(0, 0, 0, .25) !important;
  background-color: #1e2936;
  margin: 0;
  font-size: 10px;
  font-weight: normal;
}

</style>
